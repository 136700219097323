import i18n_translations from "../../../src/components/PropagoCheckout/i18n/translations";

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Associação Brasileira de Lipedema ajudando mulheres e capacitando profissionais no diagnóstico do Lipedema",
      },
      Checkout: i18n_translations["pt-BR"].translations,
      ButtonFloating: {
        button: "Doe agora",
        buttonBottom: "Faça uma doação"
      },
      HEAD00004: {
        title: "A cada <span class='bigger-text'>10 mulheres</span>, ao menos <span class='bigger-text'>1 tem lipedema</span> e provavelmente nem sabe disso",
        subTitle: "Essa é uma doença que ainda precisa de mais estudos e divulgação entre a população e a própria comunidade médica.",
      },
      COMP00002: {
        left: [
          {
            title: "Quem Atinge?",
            subTitle: "O Lipedema atinge majoritariamente mulheres e muitas vezes é associada à obesidade ou até mesmo confundida com linfedema."
          },
          {
            title: "O Que Causa?",
            subTitle: "Essa gordura que se acumula em excesso causa dor e sensibilidade ao toque nas regiões afetadas, além de inchaço, manchas roxas recorrentes, sensação de peso nas pernas e, em algumas pessoas, pés constantemente frios."
          },
        ],
        rightTitle: "O Que É O Lipedema?",
        rightSubTitle1: "Trata-se de uma patologia pouco compreendida, cujas consequências podem ser graves, caso não sejam diagnosticadas e tratadas adequadamente. Embora não haja cura para o lipedema, existem tratamentos eficazes disponíveis para ajudar a melhorar a qualidade de vida.<br/><br/>",
        rightSubTitle2: "Ela se caracteriza pelo excesso de gordura em regiões específicas, como glúteos, pernas, tornozelos e braços, tornando-os desproporcionais.",
        rightButton: "AJUDE PESSOAS COM LIPEDEMA"
      },
      COMP00003: {
        title: "O Que Oferecemos?",
        subTitle: "<p>A Associação Brasileira de Lipedema (ABL), além de oferecer e difundir informações científicas e clínicas a respeito do Lipedema, capacita profissionais da saúde e da educação, e oferece suporte a pessoas com Lipedema e a seus familiares em todo o Brasil.</p><br /><br /><p>A ABL também realiza e participa ativamente de congressos nacionais e internacionais, simpósios e palestras, e, em especial, na construção de Políticas Públicas para o Lipedema no Brasil.</p>",
        button: "CONTRIBUA COM A CAUSA"
      },
      COMP00021: {
        cards: [
          {
            title: "Para Pessoas Com Lipedema",
            subTitle: `
            <ul>
              <li>Questionário sobre lipedema</li>
              <li>Conteúdo de qualidade</li>
              <li>Acolhimento</li>
              <li>Ajuda para que encontrem o melhor tratamento</li>
              <li>Comunidade de pessoas que sofrem com essa doença</li>
              <li>Grupo de apoio</li>            
            </ul>`
          },
          {
            title: "Para Médicos",
            subTitle: `
            <ul>
              <li>Artigos científicos</li>
              <li>Curso</li>
              <li>Aulas</li>
              <li>Trocas com outros médicos</li>
              <li>Fomento de pesquisa científica em Lipedema</li>
            </ul>`
          }
        ],
      },
      //   title: "Para Pessoas Com Lipedema",
      //   subTitle: `
      //   <ul>
      //     <li><strong>Questionário sobre lipedema</strong></li>
      //     <li><strong>Conteúdo de qualidade</strong></li>
      //     <li><strong>Acolhimento</strong></li>
      //     <li><strong>Ajuda para que encontrem o melhor tratamento</strong></li>
      //     <li><strong>Comunidade de pessoas que sofrem com essa doença</strong></li>
      //   </ul>`,
      //   button: "Tenho interesse"
      // },
      // COMP00007: {
      //   title: "Para Médicos",
      //   subTitle: `
      //   <ul>
      //     <li>Artigos científicos</li>
      //     <li>​Curso</li>
      //     <li>Aulas</li>
      //     <li>Trocas com outros médicos</li>
      //   </ul>
      //   `,
      //   button: "Quero Ajudar"
      // },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de alguém.",
        legend: "Apoio"
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle: "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pela Associação Brasileira de Lipedema.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador)."
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle: "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix."
          },
          {
            title: "Por que a ABL emitiu uma cobrança em meu nome?",
            subTitle: "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos."
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle: "Não. O pagamento dos boletos enviados pela Associação Brasileira de Lipedema é totalmente facultativo."
          },
          {
            title: "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle: "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro."
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle: "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos."
          },
          {
            title: "O que é DDA?",
            subTitle: "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>"
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle: "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras."
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco."
          },
          {
            title: "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle: "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas."
          }
        ]
      },
      FOOT00001: {
        span1: "Associação Brasileira de Lipedema - ABL",
        span2: "CNPJ: 35.839.205/0001-40.",
        span3: "Fale Conosco",
        copyright: "Copyright © 2023 - Associação Brasileira de Lipedema ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como."
      },
      SectionCookies: {
        title: "A ABL se preocupa com você e sua privacidade.",
        subTitle: "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site.",
        button: "Aceito"
      }
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "Associação Brasileira de Lipedema helping women and training professionals in the diagnosis of lipedema",
      },
      Checkout: i18n_translations.en.translations,
      ButtonFloating: {
        button: "Donate Now",
        buttonBottom: "Make A Donation"
      },
      HEAD00004: {
        title: "In every <span class='bigger-text'>10 women</span>, at least <span class='bigger-text'>1 has lipedema</span> and probably does not even know it",
        subTitle: "This is a disease that still needs more studies and dissemination among the population and the medical community itself.",
      },
      COMP00002: {
        left: [
          {
            title: "Who Is Affected?",
            subTitle: "Lipedema affects mostly women and is often associated to obesity or even confused with lymphedema."
          },
          {
            title: "What Does It Cause?",
            subTitle: "This excess fat that accumulates causes pain and tenderness to touch in the affected areas, as well as swelling, recurrent purple spots, heavy feeling in the legs and, in some people, constantly cold feet."
          },
        ],
        rightTitle: "What Is Lipedema?",
        rightSubTitle1: "This is a poorly understood condition, the consequences of which can be severe if not diagnosed and treated properly. While there is no cure for lipedema, there are effective treatments available to help improve quality of life.<br/><br/>",
        rightSubTitle2: "It is characterized by excess fat in specific regions, such as buttocks, legs, ankles, and arms, making them disproportionate.",
        rightButton: "HELP PEOPLE WITH LIPEDEMA"
      },
      COMP00003: {
        title: "What Do We Do?",
        subTitle: "<p>The Associação Brasileira de Lipedema (ABL), besides offering and disseminating scientific and clinical information about Lipedema, train health and education professionals, and offer support to people with Lipedema and their families all over Brazil.</p><br /><br /><p>The ABL also carries out and actively participates in national and international congresses, symposia and lectures, and, in particular, in the construction of Public Policies for Lipedema in Brazil.</p>",
        button: "CONTRIBUTE WITH THE CAUSE"
      },
      COMP00021: {
        cards: [
          {
            title: "To People With Lipedema",
            subTitle: `
            <ul>
              <li>Questionnarie about lipedema</li>
              <li>Quality content</li>
              <li>Reception</li>
              <li>Help in finding the best treatment</li>
              <li>Community of people suffering from this disease</li>
              <li>Support group</li>            
            </ul>`
          },
          {
            title: "To Doctors",
            subTitle: `
            <ul>
              <li>Scientific papers</li>
              <li>A Course</li>
              <li>Classes</li>
              <li>Knowledge exchanges with other doctors</li>
              <li>Promotion of scientific research on Lipedema</li>
            </ul>`
          }
        ],
      },
      CHEC00001: {
        title: "Your donation will <strong>transform someone's life</strong>.",
        legend: "Powered by"
      },
      FAQS00001: {
        title: "<strong>Frequently Asked Questions</strong> about donation:",
        cards: [
          {
            title: "Does ABL accept donations in kind (clothing, food, water, etc.)?",
            subTitle: "Unfortunately, we cannot accept donations in kind."
          },
          {
            title: "How can I be sure my online donation has been accepted?",
            subTitle: "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us."
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle: "You can make your donation, whether punctual or recurring by credit card."
          },
          {
            title: "Why is monthly giving the best option?",
            subTitle: "Monthly giving is the best option for both ABL and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically."
          },
          {
            title: "Is the donation page secure?",
            subTitle: "We are proud to meet strict data security standards in order to process your donation online."
          }
        ]
      },
      FOOT00001: {
        span1: "Associação Brasileira de Lipedema",
        span2: "CNPJ: 35.839.205/0001-40.",
        span3: "Contact Us",
        copyright: "Copyright © 2023 - Associação Brasileira de Lipedema ® - All the rights can be shared with you.",
        copyrightSpan: "Ask us how."
      },
      SectionCookies: {
        title: "ABL worries about your privacy.",
        subTitle: "Our website uses cookies and other technologies to personalize your experience and understand how you and other visitors use our site. As you browse the site, we will collect such information to use for these purposes. If you do not accept them, we will use cookies only as necessary for the proper functioning of the site.",
        button: "I Accept"
      }
    },
  },
  es: {
    translations: {
      Page: {
        title: "Associação Brasileira de Lipedema ayudando a mujeres y capacitando profesionales en el diagnóstico de lipedema",
      },
      Checkout: i18n_translations.es.translations,
      ButtonFloating: {
        button: "DONAR AHORA",
        buttonBottom: "Haz Una Donación"
      },
      HEAD00004: {
        title: "De cada <span class='bigger-text'>10 mujeres</span>, al menos <span class='bigger-text'>1 padece lipedema</span> y probablemente ni siquiera lo sabe",
        subTitle: "Se trata de una enfermedad que aún necesita más estudios y difusión entre la población y la propia comunidad médica.",
      },
      COMP00002: {
        left: [
          {
            title: "¿A Quién Afecta?",
            subTitle: "El lipoedema afecta sobre todo a las mujeres y a menudo se asocia a la obesidad o incluso se confunde con el linfedema."
          },
          {
            title: "¿Cuáles Son Sus Causas?",
            subTitle: "Este exceso de grasa acumulada provoca dolor y sensibilidad al tacto en las zonas afectadas, así como hinchazón, manchas moradas recurrentes, sensación de pesadez en las piernas y, en algunas personas, pies constantemente fríos."
          },
        ],
        rightTitle: "¿Qué Es El Lipoedema?",
        rightSubTitle1: "Se trata de una enfermedad poco conocida, cuyas consecuencias pueden ser graves si no se diagnostica y trata adecuadamente. Aunque el lipedema no tiene cura, existen tratamientos eficaces que ayudan a mejorar la calidad de vida.<br/><br/>",
        rightSubTitle2: "Se caracteriza por un exceso de grasa en regiones específicas, como nalgas, piernas, tobillos y brazos.",
        rightButton: "AYUDA A LAS PERSONAS CON LIPOEDEMA"
      },
      COMP00003: {
        title: "¿Qué Hacemos?",
        subTitle: "<p>La Associação Brasileira de Lipedema (ABL), además de ofrecer y divulgar información científica y clínica sobre el Lipedema, capacita a profesionales de la salud y de la educación, y ofrece apoyo a las personas con Lipedema y a sus familiares en todo Brasil.</p><br /><br /><p>La ABL también realiza y participa activamente en congresos, simposios y conferencias nacionales e internacionales y, en particular, en la construcción de Políticas Públicas para el Lipedema en Brasil.</p>",
        button: "CONTRIBUYA A LA CAUSA"
      },
      COMP00021: {
        cards: [
          {
            title: "Para Personas Con Lipoedema",
            subTitle: `
            <ul>
              <li>Cuestionario sobre el lipoedema</li>
              <li>Contenidos de calidad</li>
              <li>Recepción</li>
              <li>Ayuda para encontrar el mejor tratamiento</li>
              <li>Comunidad de personas que padecen esta enfermedad</li>
              <li>Grupo de apoyo</li>            
            </ul>`
          },
          {
            title: "Para Los Medicos",
            subTitle: `
            <ul>
              <li>Artículos científicos</li>
              <li>Un curso</li>
              <li>Clases</li>
              <li>Intercambios de conocimientos con otros médicos</li>
              <li>Fomento de la investigación científica sobre el lipoedema</li>
            </ul>`
          }
        ],
      },
      CHEC00001: {
        title: "Tu donación <strong>transformará la vida de alguien</strong>.",
        legend: "Apoyo de"
      },
      FAQS00001: {
        title: "<strong>Preguntas Frecuentes</strong> acerca de donaciones:",
        cards: [
          {
            title: "¿ABL acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle: "Desafortunadamente, no podemos aceptar donaciones en especie."
          },
          {
            title: "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle: "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos."
          },
          {
            title: "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle: "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito."
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle: "La donación mensual es la mejor opción tanto para ABL como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente."
          },
          {
            title: "¿Es segura la página de donaciones??",
            subTitle: "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea."
          }
        ]
      },
      FOOT00001: {
        span1: "Associação Brasileira De Lipedema",
        span2: "CNPJ: 35.839.205/0001-40.",
        span3: "Póngase em contacto con nosotros",
        copyright: "Copyright © 2023 - Associacao Brasileira De Lipedema ® - Todos los derechos pueden ser compartidos contigo.",
        copyrightSpan: "Pregunta-nos cómo."
      },
      SectionCookies: {
        title: "ABL preocupase contigo e tu privacidad.",
        subTitle: "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Accepto"
      }
    },
  },
};

export default i18n;