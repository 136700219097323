import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import ButtonNoIcon from "../ButtonNoIcon";
import ButtonModel from "../ButtonModel";

// Utils
import { useConfigStore } from "../../store/config";

// Styles
import { Wrapper, WrapperText } from "./styles";

const SectionCookies = ({ onAccept }) => {
  const { t } = useTranslation();
  
  const config = useConfigStore((state) => state.config)
  const data = _.get(config, 'SectionCookies', {})
  const i18nObject = t('SectionCookies', { returnObjects: true });

  return (
    <Wrapper config={data}>
      <Row className="justify-content-center g-0 h-100">
        <Col xs={10} sm={10} md={10} lg={10} xl={8}>
          <Row className="justify-content-center g-0 h-100">
            <Col xs={12} sm={12} md={9} className="wrapper-content">
              <WrapperText title={1} config={data}>
                {t("SectionCookies.title")}
              </WrapperText>
              <WrapperText description={1} config={data}>
                {t("SectionCookies.subTitle")}
              </WrapperText>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={3}
              className="d-flex justify-content-center align-items-center wrapper-button"
            >
              <ButtonNoIcon
                primaryColor={_.get(data, "buttonPrimaryColor")}
                secondaryColor={_.get(data, "buttonSecondaryColor")}
                noIcons
                onClick={onAccept}
              >
                {t("SectionCookies.button")}
              </ButtonNoIcon>
            </Col>
          </Row>
        </Col>
      </Row>
      {data.model && (
        <ButtonModel data={data} i18n={i18nObject} title="SectionCookies" />
      )}
    </Wrapper>
  );
};

export default SectionCookies;
