import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ config }) => config.rightBackgroundColor};
  min-height: 550px;
  padding-top: 45px;
  position: relative;

  .col-total {
    min-height: 550px;
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 12px;

  .card {
    border-width: 2px;
    border-color: ${({ config }) => config.borderColor};
    background-color: ${({ config }) => config.cardColor};
    margin: 40px 20px;
    width: 420px;
    height: 310px;
    padding: 40px 15px;
    position: relative;

    @media ${devices.xs}{
      width: 300px;
      height: 380px;
    }
  }

  .card__text-container{
    height: 100%;
  }

  .icons {
    background-color: ${({ config }) => config.backgroundIconColor};
    position: absolute;
    border-radius: 60px;

    @media ${devices.xs} {
      width: 60px;
      height: 60px;
      top: -7px;
    }

    @media ${devices.sm} {
      width: 60px;
      height: 60px;
      top: -7px;
    }

    @media ${devices.md} {
      width: 70px;
      height: 70px;
      top: -13px;
    }

    @media ${devices.lg} {
      width: 106px;
      height: 106px;
      top: -30px;
    }
  }

  .images {
    @media ${devices.xs} {
      width: 30px;
      height: 30px;
    }

    @media ${devices.sm} {
      width: 30px;
      height: 30px;
    }

    @media ${devices.lg} {
      width: 60px;
      height: 60px;
    }
  }

  .title {
    color: ${({ config }) => config.fontColor};
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    border-bottom: 3px solid ${({ config }) => config.borderColor};
    margin-bottom: 15px;
    text-align: center;
  }
`;

export const WrapperSubtitle = styled.div`
  color: ${({ config }) => config.fontColor};
  font-size: 18px;
  line-height: 26px;
`;
