import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Checkout from "../../PropagoCheckout/App";
import DropdownLocale from "../../DropdownLocale";
import ButtonModel from "../../ButtonModel";

// Utils
import { useConfigStore } from "../../../store/config";

// Styles
import { Wrapper, WrapperText, Image } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const configState = useConfigStore((state) => state.config);
  const config = _.get(configState, "CHEC00001");
  const checkoutID = _.get(configState, "Checkout.id");
  const i18nObject = t('CHEC00001', { returnObjects: true });

  return (
    <Wrapper config={config} id="section-checkout">
      {config.onlyCheckout && (
        <Col className="wrapper-button-i18n">
          <DropdownLocale />
        </Col>
      )}
      {!config.onlyCheckout && t("CHEC00001.title") !== '' && (
        <Row className="justify-content-center g-0 h-100">
          <Col xs={10}>
            <WrapperText
              title={1}
              config={config}
              dangerouslySetInnerHTML={{ __html: t("CHEC00001.title") }}
            ></WrapperText>
          </Col>
        </Row>
      )}
      <Row
        className={`justify-content-center g-0 row-checkout ${
          config.onlyCheckout ? "vh-100" : ""
        }`}
      >
        <Checkout checkoutID={checkoutID} id="SectionCheckout" />
      </Row>
      {!config.onlyCheckout && (
        <Row className="justify-content-center g-0 h-100">
          <Col xs={10}>
            <WrapperText span={1} config={config}>
              {t("CHEC00001.legend")}
            </WrapperText>
          </Col>
          <Col xs={10} className="d-flex justify-content-center">
            <Image src={_.get(config, "logoPropago")} />
          </Col>
        </Row>
      )}
      {config.model && (
        <ButtonModel data={config} i18n={i18nObject} title="CHEC00001" />
      )}
    </Wrapper>
  );
};

export default Wireframe;
