import styled from 'styled-components'

export const Wrapper = styled.div`
  z-index: 2;
  cursor: pointer;
  background-color: rgba(255,255,255,0.8);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  ${({pBlock}) => pBlock ? '' : `
    position: absolute;
    top: 8px;
    right: 8px;
  `}

  svg {
    width: 20px;
    height: 20px;
  }
`
export const Copy = styled.div`
  margin-left: 20px;
  cursor: pointer;
`

export const Back = styled.div`
  margin-left: 20px;
  cursor: pointer;
`
