import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  
  background-color: ${({ config }) => config.backgroundColor};
  padding-top: 50px;
  padding-bottom: 75px;

  .row-accordion {
    margin-top: 50px;
  }

  .accordion-item {
    background-color: transparent;
    border: 0;
    margin-bottom: 15px;
  }
  .accordion-button {
    background-color: transparent;
    box-shadow: none;
    background-color: ${({ config }) => config.primaryColor};
    border: 1px solid ${({ config }) => config.primaryColor};
    border-radius: 5px !important;
    padding: 15px 20px 15px 20px !important;
  }
  .accordion-button.collapsed {
    background-color: transparent;
    border: 1px solid ${({ config }) => config.primaryColor};
    border-radius: 5px !important;
    padding: 15px 20px 15px 20px !important;

    .accordion-header .number {
      color: ${({ config }) => config.primaryColor};
    }
    .accordion-header .bar {
      background-color: ${({ config }) => config.primaryColor};
    }
    .accordion-header .description {
      color: ${({ config }) => config.primaryColor};
    }
  }
  .accordion-header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .number {
      font-size: 20px;
      font-weight: 600;
      color: ${({ config }) => config.secondaryColor};
    }
    .bar {
      width: 1px;
      height: 17px;
      background-color: ${({ config }) => config.secondaryColor};
      margin-right: 15px;
      margin-left: 15px;
    }
    .description {
      font-size: 15px;
      font-weight: ${({config}) => config.textRegular ? 400 : 200}};
      color: ${({ config }) => config.secondaryColor};
    }
  }

  .accordion-body {
    color: ${({ config }) => config.primaryColor};
    font-size: 15px;
    font-weight: ${({config}) => config.textRegular ? 400 : 200}};
    line-height: 24px;
    a {
      color: ${({ config }) => config.primaryColor};
    }
  }

  ///.accordion-button.collapsed::after {
  ///  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.631' height='22.629' viewBox='0 0 22.631 22.629'%3E%3Cpath id='Uni%C3%A3o_43' data-name='Uni%C3%A3o 43' d='M13904 11728v-7h-7v-2h7v-7h2v7h7v2h-7v7Z' transform='translate(-1533.713 -18108.297) rotate(45)' fill='${({
    config,
  }) =>
    `${config.primaryColor}`.replace("#", "%23")}'/%3E%3C/svg%3E") !important;
  ///}
  //.accordion-button:not(.collapsed)::after {
  //  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.631' height='22.629' viewBox='0 0 22.631 22.629'%3E%3Cpath id='Un2i%C3%A3o_43' data-name='Uni%C3%A3o 43' d='M13904 11728v-7h-7v-2h7v-7h2v7h7v2h-7v7Z' transform='translate(-1533.713 -18108.297) rotate(45)' fill='${({
    config,
  }) => `${config.secondaryColor}`.replace("#", "%23")}'/%3E%3C/svg%3E");
  //}
  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg id='PLUS_-_POSITIVO' data-name='PLUS - POSITIVO' xmlns='http://www.w3.org/2000/svg' width='21.002' height='21.002' viewBox='0 0 14.002 14  .002'%3E%3Cpath id='União_42' data-name='União 42' d='M13904,11728v-7h-7v-2h7v-7h2v7h7v2h-7v7Z' transform='translate(-13896.999 -11711.999)' fill='${({
      config,
    }) => `${config.primaryColor}`.replace("#", "%23")}'/%3E%3C/svg%3E%0A");
    width: 16px !important;
    height: 16px !important;
  }
`;

export const WrapperText = styled.div`
  ${({ title, config }) =>
    title
      ? `
    font-size: 35px;
    line-height: 40px;
    color: ${config.titleColor};
    text-align: center;
    font-weight: ${config.textRegular ? 400 : 200};
    
    strong {
      font-weight: 600;
    }

    @media ${devices.xs} {
      font-size: 30px;
    }
  `
      : ""}
`;
