import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;

  .row-checkout {
    @media ${devices.xm} {
    }
    @media ${devices.sm} {
    }
    @media ${devices.md} {
      height: 700px;
    }
  }
  @media ${devices.lg} {
    .row-checkout .app-header .header {
      padding-right: 10%;
      padding-left: 10%;
    }
    .row-checkout .app-content {
      padding-right: 5%;
      padding-left: 5%;
    }
    .row-checkout .app-footer {
      padding-right: 10%;
      padding-left: 10%;
    }
  }
  @media ${devices.xl} {
    .row-checkout .app-header .header {
      padding-right: 18%;
      padding-left: 18%;
    }
    .row-checkout .app-content {
      padding-right: 10%;
      padding-left: 10%;
    }
    .row-checkout .app-footer {
      padding-right: 18%;
      padding-left: 18%;
    }
  }

  .wrapper-button-i18n {
    position: absolute;
    z-index: 2;

    @media ${devices.xs} {
      left: 15px;
      top: 58px;
    }
    @media ${devices.sm} {
      left: 15px;
      top: 18px;
    }
  }
`;

export const WrapperText = styled.div`
  ${({ title, config }) =>
    title
      ? `
    font-size: 34px;
    color: ${config.mainColor};
    text-align: center;
    line-height: 40px;

    margin-top: 50px;
    margin-bottom: 20px;

    @media ${devices.xs} {
      font-size: 24px;
      line-height: 30px;
    }
  `
      : ""}

  ${({ span, config }) =>
    span
      ? `
    font-size: 15px;
    color: ${config.secondaryColor};
    text-align: center;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 20px;
  `
      : ""}
`;

export const Image = styled.img`
  object-fit: contain;
  max-width: 173px;
  margin-bottom: 50px;
`;
