import _ from "lodash";
import { Col, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import ButtonModel from "../../ButtonModel";

// Assets
import { ReactComponent as FacebookIcon } from "../../../assets/svgs/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/svgs/instagram.svg";
import { ReactComponent as WhatsappIcon } from "../../../assets/svgs/whatsapp.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/svgs/youtube.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/svgs/linkedin.svg";
// import { ReactComponent as LinkedinIcon } from "../../../assets/svgs/linkedin.svg"; 
// COMO NÃO SEI ONDE TU PEGOU O ÍCONE, NÃO BAIXEI NADA, DEPOIS TERIA QUE INCLUIR DEPOIS NA DIV COM 
// {_.get(config, "urlLinkedin") !== "" && (
//   <a
//     href={_.get(config, "urlLinkedin")}
//     target="_blank"
//     rel="noreferrer"
//   >
//     <LinkedinIcon />
//   </a>
// )}

// Utils
import { useConfigStore } from "../../../store/config";

// Styles
import { Wrapper, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const configState = useConfigStore((state) => state.config);
  const config = _.get(configState, "FOOT00001");
  const i18nObject = t('FOOT00001', { returnObjects: true });

  return (
    <Wrapper config={config}>
      <Row className="justify-content-center g-0 h-100">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Row className="justify-content-center g-0 h-100">
            <Col
              xs={12}
              lg={3}
              className="d-flex flex-lg-column justify-content-center"
            >
              <div>
                <Image src={_.get(config, "image")} alt="Logo da ABL" className="image" fluid />
              </div>
            </Col>
            <Col
              xs={12}
              lg={8}
              xl={7}
              className="d-flex flex-column justify-content-center wrapper-content"
            >
              <div>
                <WrapperText main={1} config={config}>
                  {t("FOOT00001.span1")}
                </WrapperText>
                <WrapperText main={1} config={config}>
                  {t("FOOT00001.span2")}
                </WrapperText>
                <WrapperText main={1} config={config}>
                  <a
                    href={_.get(config, "urlMail")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("FOOT00001.span3")}
                  </a>
                </WrapperText>
              </div>
              <div className="d-flex justify-content-center justify-content-lg-end align-items-lg-end wrapper-icons">
                {_.get(config, "urlFacebook") !== "" && (
                  <a
                    href={_.get(config, "urlFacebook")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                )}
                {_.get(config, "urlInstagram") !== "" && (
                  <a
                    href={_.get(config, "urlInstagram")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                )}
                {_.get(config, "urlLinkedin") !== "" && (
                  <a
                    href={_.get(config, "urlLinkedin")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedinIcon />
                  </a>
                )}
                {_.get(config, "urlWhatsapp") !== "" && (
                  <a
                    href={_.get(config, "urlWhatsapp")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatsappIcon />
                  </a>
                )}
                {_.get(config, "urlYoutube") !== "" && (
                  <a
                    href={_.get(config, "urlYoutube")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YoutubeIcon />
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center g-0 h-100 wrapper-copyright">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <WrapperText copyright={1} config={config}>
            {t("FOOT00001.copyright")}{" "}
            <a
              href={_.get(config, "urlCopyrightSpan")}
              target="_blank"
              rel="noreferrer"
            >
              {t("FOOT00001.copyrightSpan")}
            </a>
          </WrapperText>
        </Col>
      </Row>
      {config.model && (
        <ButtonModel data={config} i18n={i18nObject} title="FOOT00001" />
      )}
    </Wrapper>
  );
};

export default Wireframe;
