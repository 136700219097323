import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  
  .col-left {
    padding-top: 72px;
    padding-bottom: 42px;
    background-image: url(${({ config }) => config.backgroundImageLeft});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media ${devices.md} {
      padding-right: 48px;
    }
  }

  .col-right {
    background-color: ${({ config }) => config.leftNumberColor};
  }

  @media ${devices.xs} {
    .col-right {
      padding: 10px;
    }
  }
`;

export const Image = styled.img`
  margin-bottom: ${({ config }) => config.marginLogo};
`;

export const WrapperCard = styled.div`
  width: 350px;
  margin-bottom: 49px;
  
  .content {
    background-color: ${({ config }) => config.leftCardBackgroundColor};
    width: 350px;
    min-height: 178px;
    border-radius: 12px;
    padding: 20px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .content-center {
    justify-content: center;
  }
  .content-percent {
    color: ${({ config }) => config.leftMainColor};
    font-size: 24px;
    border-bottom: 4px solid ${({ config }) => config.leftMainColor};
    margin-bottom: 10px;
    font-weight: 600;
    text-align: center;
  }
  .content-description {
    color: ${({ config }) => config.leftDescriptionColor};
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  @media ${devices.xs} {
    width: 300px;

    .content{
      width: 300px;
    }
  }
`;

export const WrapperText = styled.div`
  ${({ title, config }) =>
    title
      ? `
    font-size: 24px;
    line-height: 40px;
    color: ${config.rightTitleColor};
    font-weight: 600;
    text-align: center;
  `
      : ""}

  ${({ description, config }) =>
    description
      ? `
    font-size: 18px;
    line-height: 30px;
    font-weight: 200;
    text-align: justify;
    color: ${config.rightDescriptionColor};
  `
      : ""}

  margin-bottom: 30px;
`;
