import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const WrapperBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px;
  background-color: ${({ config }) => config.barColor };

  .logo{
    flex: 0 0 auto;

    @media ${devices.xs} {
      width: 65%
    }

    @media ${devices.sm} {
      width: 45%;
    }

    @media ${devices.md} {
      width: 35%;
    }

    @media ${devices.lg} {
      width: 25%;
    }
  }

  .imageSelo {
    @media ${devices.xs}{
      display: none !important;
    }
    
    @media ${devices.sm}{
      width: 12%;
      height: 80px;
    }

    @media ${devices.md} {
      width: 45%;
      height: 100px;
    }
 
    @media ${devices.lg} {
      width: 35%;
    }
  }

  .wrapper-button-i18n{
    position: absolute;

    @media ${devices.xs} {
      right: 2%;
      top: 15%;
    }

    @media ${devices.sm} {
      right: 43%;
    }

    @media ${devices.md} {
      right: 55%;
    }

    @media ${devices.lg} {
      top: 30%;
      right: 50%;
    }
  }
`;

export const WrapperPhoto = styled.div`
  ${({ config }) =>
  config.heightMaxScreen ? `${config.heightMaxScreen}px` : ""};
  padding: 12em 0;
  background-image: url(${({ config }) => config.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .text-container{
    justify-content: flex-end;

    @media ${devices.xs} {
      justify-content: center;
      text-align: center;
      margin-right: 0 !important;
    }
  }

  .title {
    color: ${({ config }) => config.primaryColor};
    font-weight: 800;
    margin-top: 20px;

    @media ${devices.xs} {
      font-size: 22px;
      line-height: 33px;
    }

    @media ${devices.sm} {
      font-size: 26px;
      line-height: 38px;
    }

    @media ${devices.md} {
      font-size: 29px;
      line-height: 55px;
    }

    @media ${devices.lg} {
      font-size: 32px;
      line-height: 45px;
    }

    @media ${devices.xl} {
      font-size: 35px;
      line-height: 45px;
    }
  }

  .bigger-text{
    font-size: 1.5em;
    text-transform: uppercase;
  }

  .sub-title {
    color: ${({ config }) => config.primaryColor};
    font-weight: 700;

    @media ${devices.xs} {
      font-size: 15px;
      line-height: 18px;
      margin: 40px 0;
    }

    @media ${devices.sm} {
      font-size: 16px;
      line-height: 20px;
      margin: 40px 0;
      padding-right: 3em;
    }

    @media ${devices.md} {
      font-size: 18px;
      line-height: 22px;
      margin: 40px 0;
      padding-right: 8em;
    }

    @media ${devices.lg} {
      margin: 50px 0;
      font-size: 20px;
      line-height: 24px;
      padding-right: 9.5em;
    }
  }
`;

export const ArrowWrapper = styled.div`
  height: 90px;
  margin-top: -90px;
  display: flex;
  justify-content: center;

  svg {
    cursor: pointer;

    @media ${devices.xs} {
      width: 43px;
    }

    @media ${devices.sm} {
      width: 48px;
    }

    @media ${devices.md} {
      width: 57px;
    }
  }

  svg #Caminho_247 {
    fill: ${({ config }) => config.arrowColor};
  }

  svg #Elipse_283 {
    stroke: ${({ config }) => config.arrowColor};
    fill: transparent;
  }
`;
