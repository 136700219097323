// Assets
import { ReactComponent as HeartButton } from "../../assets/svgs/heartButton.svg";

// Styles
import {
  Wrapper,
} from './styles'

const Button = ({
  children,
  primaryColor,
  secondaryColor,
  borderFixedColor,
  borderFixedHoverColor,
  onClick = () => {},
  icon = true,
  displayValue = false,
  buttonWidthVariable = false,
}) => {
  return (
    <Wrapper
      className="noselect"
      displayValue={displayValue}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      borderFixedColor={borderFixedColor}
      borderFixedHoverColor={borderFixedHoverColor}
      onClick={onClick}
      buttonWidthVariable={buttonWidthVariable}
    >
      {icon && (<HeartButton />)}
      {children}
      {icon && (<HeartButton />)}
    </Wrapper>
  )
}

export default Button