import i18n from "./i18n";

const lipedema = {
  Checkout: {
    id: process.env.REACT_APP_CHECKOUT_ID,
  },
  GTM: {
    tag: "GTM-5BHCS32",
  },
  SectionsOrder: [
    "HEAD00004",
    "COMP00002",
    "COMP00003",
    "COMP00021",
    "CHEC00001",
    "FAQS00001",
    "FOOT00001"
  ],
  ButtonFloating: {
    buttonPrimaryColor: "#348C18",
    buttonSecondaryColor: "#FFFFFF",
    borderFixedColor: "#E3D7EB",
    borderFixedHoverColor: "#348C18",
    bottomColor: "#FFFFFF",
    scrollTo: "section-checkout",
  },
  HEAD00004: {
    backgroundImage: "/assets/lipedema/HEAD00004-img1.png",
    imageLogo: `/assets/lipedema/HEAD00004-logo.png`,
    imageSelo: `/assets/selo.png`,
    barColor: "rgba(161, 68, 147, .6)",
    primaryColor: "#FFFFFF",
    secundaryColor: "#E3D7EB",
    buttonDisplayValue: true,
    buttonPrimaryColor: "#348C18",
    buttonSecondaryColor: "#FFFFFF",
    hasButtonIcon: false,
    isButtonWidthVariable: true,
    scrollTo: "COMP00002",
  },
  COMP00002: {
    backgroundImageLeft: `/assets/lipedema/COMP00002-img1.jpg`,
    icon: ``,
    marginLogo: "0px",
    leftMainColor: "#A14493",
    leftNumberColor: "#FFFFFF",
    leftDescriptionColor: "#A14493",
    leftCardBackgroundColor: "#E3D7EB",
    buttonPrimaryColor: "#348C18",
    buttonSecondaryColor: "#FFFFFF",
    rightTitleColor: "#A14493",
    rightDescriptionColor: "#A14493",
    hasButtonIcon: false,
    isButtonWidthVariable: true,
    scrollTo: "section-checkout",
  },
  COMP00003: {
    backgroundImageRight: "/assets/lipedema/COMP00003-img1.jpg",
    marginLogo: "30px",
    primaryColor: "#A14493",
    secondaryColor: "#A14493",
    buttonPrimaryColor: "#348C18",
    buttonSecondaryColor: "#FFFFFF",
    position: "justify",
    hasButtonIcon: false,
    isButtonWidthVariable: true,
    scrollTo: "section-checkout"
  },
  COMP00021 : {
    fontColor: "#A14493",
    cardColor: "#FFFFFF",
    backgroundIconColor: "#E3D7EB",
    rightBackgroundColor: "#A14493",
    borderColor: "#8B68AA",
    rightDescriptionColor: "#FFFFFF",
    icons: [
      `<svg xmlns="http://www.w3.org/2000/svg" fill="#A14493" viewBox="0 0 24 24"><path d="M7.38938 16.5386C5.33894 15.0901 4 12.7014 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 12.7014 18.6611 15.0901 16.6106 16.5386L18.6936 21.2996C18.8043 21.5526 18.6889 21.8474 18.4359 21.9581C18.3727 21.9857 18.3045 22 18.2355 22H5.76451C5.48837 22 5.26451 21.7761 5.26451 21.5C5.26451 21.431 5.27878 21.3628 5.30643 21.2996L7.38938 16.5386ZM8.11851 10.9704C8.55217 12.7106 10.1255 14 12 14C13.8745 14 15.4478 12.7106 15.8815 10.9704L13.9407 10.4852C13.7239 11.3553 12.9372 12 12 12C11.0628 12 10.2761 11.3553 10.0593 10.4852L8.11851 10.9704Z"></path></svg>`,
      `<svg xmlns="http://www.w3.org/2000/svg" fill="#A14493" viewBox="0 0 24 24"><path d="M16 1C16.5523 1 17 1.44772 17 2V5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7V2C7 1.44772 7.44772 1 8 1H16ZM20 7H4V19H20V7ZM13 9V12H16V14H12.999L13 17H11L10.999 14H8V12H11V9H13ZM15 3H9V5H15V3Z"></path></svg>`
    ],
    scrollTo: "section-checkout"
  },
  CHEC00001: {
    mainColor: "#A14493",
    secondaryColor: "#FFB100",
    logoPropago: "/assets/lipedema/CHEC00001-logo.png",
    onlyCheckout: false
  },
  FAQS00001: {
    backgroundColor: "#E3D7EB",
    primaryColor: "#A14493",
    secondaryColor: "#FFFFFF",
    titleColor: "#A14493"
  },
  FOOT00001: {
    backgroundColor: "rgba(161, 68, 147, .6)",
    copyrightBackgroundColor: "#FFFFFF",
    image: `/assets/lipedema/FOOT00001-logo.png`,
    mainColor: "#FFFFFF",
    copyrightColor: "#A14493",
    socialIconsColor: "#FFFFFF",
    urlMail: "https://lipedema.org.br/contato-associacao-brasileira-lipedema/",
    urlCopyrightSpan: "http://www.propago.com.br",
    urlFacebook: "https://web.facebook.com/groups/lipoedema/?ref=bookmarks&_rdc=1&_rdr",
    urlInstagram: "https://www.instagram.com/lipedema_brasil/",
    urlLinkedin: "",
    urlYoutube: "https://www.youtube.com/playlist?list=PLgZHHPRpXG5_roEjG7Dvnp8XQoO5W_-d_",
    urlWhatsapp: ""
  },
  // COMENTEI SÓ PRA NÃO FICAR APARECENDO
  SectionCookies: {
    backgroundColor: "#A14493",
    mainColor: "#FFFFFF",
    buttonPrimaryColor: "#FFFFFF",
    buttonSecondaryColor: "#A14493",
  }, 
  i18n,
};

export default lipedema;