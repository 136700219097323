import { Row, Col, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// Components
import ButtonModel from "../../ButtonModel";

// Utils
import { useConfigStore } from "../../../store/config";

// Styles
import { Wrapper, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const configState = useConfigStore((state) => state.config);
  const config = _.get(configState, "FAQS00001");

  const cards = t("FAQS00001.cards", { returnObjects: true });
  const i18nObject = t('FAQS00001', { returnObjects: true });

  return (
    <Wrapper config={config}>
      <Row className="justify-content-center g-0 h-100">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <WrapperText
            title={1}
            config={config}
            dangerouslySetInnerHTML={{ __html: t("FAQS00001.title") }}
          ></WrapperText>
        </Col>
      </Row>
      <Row className="justify-content-center g-0 h-100 row-accordion">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Accordion defaultActiveKey="0" flush>
            {_.map(cards, (item, index) => (
              <Accordion.Item key={index} eventKey={`${index}`}>
                <Accordion.Header>
                  <div className="accordion-header">
                    <div className="number">
                      {`${index + 1}`.padStart(2, "0")}
                    </div>
                    <div className="bar" />
                    <div className="description">{_.get(item, "title")}</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: _.get(item, "subTitle"),
                    }}
                  ></div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
      {config.model && (
        <ButtonModel data={config} i18n={i18nObject} title="FAQS00001" />
      )}
    </Wrapper>
  );
};

export default Wireframe;
