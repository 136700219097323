import { useState } from "react";

// Libs
import _ from "lodash";
import { BrowserRouter } from "react-router-dom";

// Components
import Wireframes from "./components/Wireframes";
import SectionCookies from "./components/SectionCookies";
import ButtonDonateNow from "./components/ButtonDonateNow";

// Utils
import { useConfigStore } from "./store/config";
import { startI18N } from "./i18n";

function App() {

  const config = useConfigStore((state) => state.config);
  const sectionsOrder = _.get(config, "SectionsOrder");
  const sectionCookies = _.get(config, "SectionCookies");

  const [acceptedCookies, setAcceptedCookies] = useState(false);

  startI18N(config.i18n);


  return (
    <div className="lp-app notranslate">
      <Wireframes sectionsOrder={sectionsOrder} />
      <ButtonDonateNow />
      {!_.isEmpty(sectionCookies) && acceptedCookies === false && (
        <SectionCookies onAccept={() => setAcceptedCookies(true)} />
      )}
    </div>
  );
}

const AppContainer = (props) => {
  return (
    <BrowserRouter>
      <App {...props} />
    </BrowserRouter>
  );
};

export default AppContainer;
