import _ from "lodash";
import { useTranslation } from "react-i18next";

// Components
import Button from "../Button";
import ButtonModel from "../ButtonModel";

// Utils
import { useConfigStore } from "../../store/config";
import { scrollTo } from "../../utils/helperScrools";

// Assets
import { ReactComponent as HeartButton } from "../../assets/svgs/heartButton.svg";

// Styles
import { WrapperButtonFloating, WrapperButtonFloatingBottom } from "./styles";

const ButtonDonateNow = () => {
  const { t } = useTranslation();
  const config = useConfigStore((state) => state.config);

  const data = _.get(config, "ButtonFloating");
  const i18nObject = t('ButtonFloating', { returnObjects: true });

  if (_.isEmpty(data)) {
    return <></>;
  } else {
    return (
      <>
        <WrapperButtonFloating className="d-none d-md-block">
          <Button
            primaryColor={_.get(data, "buttonPrimaryColor")}
            secondaryColor={_.get(data, "buttonSecondaryColor")}
            borderFixedColor={_.get(data, "borderFixedColor")}
            borderFixedHoverColor={_.get(data, "borderFixedHoverColor")}
            onClick={() => {
              scrollTo(_.get(data, "scrollTo"));
            }}
          >
            {t("ButtonFloating.button")}
          </Button>
          {data.model && (
            <ButtonModel data={data} i18n={i18nObject} title="ButtonFloating" />
          )}
        </WrapperButtonFloating>
        <WrapperButtonFloatingBottom
          className="d-none"
          config={data}
          onClick={() => {
            scrollTo(_.get(data, "scrollTo"));
          }}
        >
          <HeartButton />
          {t("ButtonFloating.buttonBottom")}
          {data.model && (
            <ButtonModel data={data} i18n={i18nObject} title="ButtonFloating" />
          )}
        </WrapperButtonFloatingBottom>
      </>
    );
  }
};

export default ButtonDonateNow;
