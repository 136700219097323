import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";
import ButtonModel from "../../ButtonModel";

// Utils
import { useConfigStore } from "../../../store/config";
import { scrollTo } from "../../../utils/helperScrools";

// Styles
import {
  Wrapper,
  WrapperCard,
  WrapperText,
} from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const configState = useConfigStore((state) => state.config);
  const config = _.get(configState, "COMP00002");
  const i18nObject = t('COMP00002', { returnObjects: true });

  const left = t("COMP00002.left", { returnObjects: true });

  return (
    <Wrapper config={config} id="COMP00002">
      <Row className="justify-content-center g-0 h-100">
        <Col
          xm={12}
          sm={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center align-items-md-end col-left"
        >
          {_.map(left, (item, index) => (
            <WrapperCard config={config} key={index}>
              <div className={`content ${_.isEmpty(_.get(item, "title")) ? 'content-center' : ''}`}>
                <div className="content-percent">{_.get(item, "title")}</div>
                <div className="content-description">
                  {_.get(item, "subTitle")}
                </div>
              </div>
            </WrapperCard>
          ))}
        </Col>
        <Col
          xm={12}
          sm={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center align-items-md-center col-right"
        >
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            <WrapperText
              title={1}
              config={config}
              dangerouslySetInnerHTML={{ __html: t("COMP00002.rightTitle") }}
            ></WrapperText>
          </Col>
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            <WrapperText
              description={1}
              config={config}
              dangerouslySetInnerHTML={{
                __html: t("COMP00002.rightSubTitle1"),
              }}
            ></WrapperText>
          </Col>
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            <WrapperText
              description={1}
              config={config}
              dangerouslySetInnerHTML={{
                __html: t("COMP00002.rightSubTitle2"),
              }}
            ></WrapperText>
          </Col>
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            <Button
                primaryColor={_.get(config, "buttonPrimaryColor")}
                secondaryColor={_.get(config, "buttonSecondaryColor")}
                onClick={() => {
                  scrollTo(_.get(config, "scrollTo"));
                }}
                icon={config.hasButtonIcon}
                buttonWidthVariable={config.isButtonWidthVariable}
              >
                {t("COMP00002.rightButton")}
            </Button>
          </Col>
        </Col>
      </Row>
      {config.model && (
        <ButtonModel data={config} i18n={i18nObject} title="COMP00002" />
      )}
    </Wrapper>
  );
};

export default Wireframe;
