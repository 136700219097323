import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  
  .col-left {
    background-color: ${({ config }) => config.buttonSecondaryColor};
    padding-bottom: 30px;
    @media ${devices.xs} {
      padding: 30px 10px;
    }
  }

  .col-right {
    background-image: url(${({ config }) => config.backgroundImageRight});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const Image = styled.img`
  margin-bottom: ${({ config }) => config.marginLogo};
`;

export const WrapperText = styled.div`
  ${({ title, config }) =>
    title
      ? `
    font-size: 24px;
    line-height: 40px;
    color: ${config.rightTitleColor};
    font-weight: 600;
    text-align: center;
    color: ${config.primaryColor};
    margin-bottom: 20px;
  `
      : ""}

  ${({ description, config }) =>
    description
      ? `
    font-size: 18px;
    line-height: 30px;
    font-weight: 200;
    color: ${config.secondaryColor};
    text-align: ${config.position};
    margin-bottom: 30px;
  `
      : ""}
`;
