import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import ButtonModel from "../../ButtonModel";

// Utils
import { useConfigStore } from "../../../store/config";

// Styles
import { Wrapper, WrapperCard, WrapperSubtitle } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const configState = useConfigStore((state) => state.config);
  const config = _.get(configState, "COMP00021");

  const cards = t("COMP00021.cards", { returnObjects: true });
  const i18nObject = t('COMP00021', { returnObjects: true });

  return (
    <Wrapper config={config} id="COMP00021">
      <Row className="d-flex justify-content-center align-items-center flex-column g-0 h-100 col-total">
        <Col
          xm={12}
          className="d-flex justify-content-center align-items-center flex-column flex-md-row"
        >
          {_.map(cards, (item, index) => (
            <WrapperCard config={config} key={index}>
              <div className="d-flex card">
                <div className="title">{_.get(item, "title")}</div>
                <WrapperSubtitle config={config} className="subTitle" dangerouslySetInnerHTML={{ __html: _.get(item, "subTitle")}}></WrapperSubtitle>
              </div>
              <div className="d-flex justify-content-center align-items-center icons">
                <div
                  className="images"
                  dangerouslySetInnerHTML={{ __html: _.get(config, `icons.${index}`)}}
                />
              </div>
            </WrapperCard>
          ))}
        </Col>
      </Row>
      {config.model && (
        <ButtonModel data={config} i18n={i18nObject} title="COMP00021" />
      )}
    </Wrapper>
  );
};

export default Wireframe;
