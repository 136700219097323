import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";
import ButtonModel from "../../ButtonModel";

// Utils
import { useConfigStore } from "../../../store/config";
import { scrollTo } from "../../../utils/helperScrools";

// Styles
import { Wrapper, Image, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const configState = useConfigStore((state) => state.config);
  const config = _.get(configState, "COMP00003");
  const i18nObject = t('COMP00003', { returnObjects: true });

  return (
    <Wrapper config={config} id="COMP00003">
      <Row className="justify-content-center g-0 h-100">
        <Col
          xm={12}
          sm={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center align-items-md-end col-left"
        >
          {!_.isEmpty(config, "icon") && (
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={8}
              xl={6}
              className="d-flex justify-content-center"
            >
              <Image config={config} src={_.get(config, "icon")} />
            </Col>
          )}
          {!_.isEmpty(t("COMP00003.title")) && (
            <Col xs={10} sm={10} md={10} lg={8} xl={8} className="m-auto">
              <WrapperText
                title={1}
                config={config}
                dangerouslySetInnerHTML={{ __html: t("COMP00003.title") }}
              ></WrapperText>
            </Col>
          )}
          <Col xs={10} sm={10} md={10} lg={8} xl={8} className="m-auto">
            <WrapperText
              description={1}
              config={config}
              dangerouslySetInnerHTML={{ __html: t("COMP00003.subTitle") }}
            ></WrapperText>
          </Col>
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            xl={8}
            className="m-auto"
          >
            <Button
              primaryColor={_.get(config, "buttonPrimaryColor")}
              secondaryColor={_.get(config, "buttonSecondaryColor")}
              onClick={() => {
                scrollTo(_.get(config, "scrollTo"));
              }}
              icon={config.hasButtonIcon}
              buttonWidthVariable={config.isButtonWidthVariable}
            >
              {t("COMP00003.button")}
            </Button>
          </Col>
        </Col>
        <Col
          xm={12}
          sm={12}
          md={6}
          className="d-none d-md-flex flex-column justify-content-center align-items-center align-items-md-start col-right"
        ></Col>
      </Row>
      {config.model && (
        <ButtonModel data={config} i18n={i18nObject} title="COMP00003" />
      )}
    </Wrapper>
  );
};

export default Wireframe;
