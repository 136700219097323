import styled from "styled-components";
import devices from "../../utils/helperGrids";

export const WrapperDropdown = styled.div`
  font-size: 15px;
  color: #787878;
  font-weight: 200;
  position: relative;

  .dropdown-locale-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    padding: 11px 11px 11px 11px;
    border-radius: 5px;
    cursor: pointer;

    @media ${devices.xs} {
      width: 71px;
    }
    @media ${devices.sm} {
      width: 71px;
    }
    @media ${devices.xl} {
      width: 156px;
    }
  }
  .dropdown-locale-arrow {
    margin-top: 4px;
  }
  .dropdown-locale-menu {
    background-color: #f2f2f2;
    padding: 11px 11px 0px 11px;
    border-radius: 5px;
    position: absulute;
    transform: translateY(4px);

    @media ${devices.xs} {
      width: 71px;
    }
    @media ${devices.sm} {
      width: 71px;
    }
    @media ${devices.xl} {
      width: 156px;
    }
  }
  .dropdown-locale-item {
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 6px;
    }
  }
  .dropdown-locale-item-selected {
    font-weight: 600;
  }
`;
