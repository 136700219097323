import styled from "styled-components";

export const WrapperButtonFloating = styled.div`
  position: fixed;
  top: 38px;
  right: 40px;
  z-index: 2;
`

export const WrapperButtonFloatingBottom = styled.div`
  position: fixed;
  width: 100%;
  background-color: ${({config}) => config.bottomBackgroundColor};
  color: ${({config}) => config.bottomColor};
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-transform: uppercase;
  corsor: pointer;
  height: 80px;
  svg {
    fill: ${({config}) => config.bottomColor};
    margin-bottom: 10px;
    width: 22px;
    height: 22px;
  }
`