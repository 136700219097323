import _ from "lodash";
import { Image, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Utils
import { useConfigStore } from "../../../store/config";
import { scrollTo } from "../../../utils/helperScrools";

// Components
import DropdownLocale from "../../DropdownLocale";
import ButtonModel from "../../ButtonModel";

// Assets
import { ReactComponent as ArrowDown } from "../../../assets/svgs/arrowDown.svg";

// Styles
import { Wrapper, WrapperBar, WrapperPhoto, ArrowWrapper } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const configState = useConfigStore((state) => state.config);
  const config = _.get(configState, "HEAD00004");
  const i18nObject = t('HEAD00004', { returnObjects: true });

  return (
    <Wrapper config={config}>
      <div>
        <WrapperBar config={config}>
          <Col className="d-flex justify-content-start logo">
            <Image src={_.get(config, "imageLogo")} alt="Logo da ABL" fluid />
          </Col>
          <div className="d-flex justify-content-start imageSelo">
            <Image src={_.get(config, "imageSelo")} />
          </div>
          <div className="wrapper-button-i18n">
            <Col>
              <DropdownLocale />
            </Col>
          </div>
        </WrapperBar>
        <WrapperPhoto config={config}>
          <div className="d-flex text-container">
            <Col
              xs={10}
              md={10}
              lg={8}
              xl={8}
              className="text-justify title"
              dangerouslySetInnerHTML={{ __html: t("HEAD00004.title") }}
            ></Col>
          </div>
          <div className="d-flex text-container">
            <Col xs={10} md={10} lg={8} xl={8} className="text-center sub-title">
              {t("HEAD00004.subTitle")}
            </Col>
          </div>
        </WrapperPhoto>
      </div>
      <div>
        <ArrowWrapper
          config={config}
          onClick={() => {
            scrollTo(_.get(config, "scrollTo"));
          }}
        >
          <ArrowDown />
        </ArrowWrapper>
      </div>
      {config.model && (
        <ButtonModel data={config} i18n={i18nObject} title="HEAD00004" />
      )}
    </Wrapper>
  );
};

export default Wireframe;
