// Libs
import _ from "lodash";

// Components
import COMP00002 from "./COMP00002";
import COMP00003 from "./COMP00003";
import COMP00021 from "./COMP00021";
import CHEC00001 from "./CHEC00001";
import FAQS00001 from "./FAQS00001";
import FOOT00001 from "./FOOT00001";
import HEAD00004 from "./HEAD00004";

const Wireframes = ({ sectionsOrder }) => {
  return (
    <>
      {_.map(sectionsOrder, (section) => {
        const name = section.split(".")[0];

        const data = {key: section, name: section}

        switch (name) {
          case "HEAD00004":
            return <HEAD00004 {...data} />;
          case "COMP00002":
            return <COMP00002 {...data} />;
          case "COMP00003":
            return <COMP00003 {...data} />;
          case "CHEC00001":
            return <CHEC00001 {...data} />;
          case "FAQS00001":
            return <FAQS00001 {...data} />;
          case "COMP00021":
            return <COMP00021 {...data} />;
          case "FOOT00001":
            return <FOOT00001 {...data} />;
          default:
            return <></>
        }
      })}
    </>
  );
};

export default Wireframes;
