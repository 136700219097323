import {useState} from 'react'

// Libs
import Modal from 'react-bootstrap/Modal';

// Assets
import { ReactComponent as Config } from "../../assets/svgs/config.svg";

// Styles
import {
  Wrapper,
  Copy,
  Back
} from './styles'

const Button = ({data, title, i18n, pBlock}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Wrapper
        pBlock={pBlock}
        className="noselect"
        onClick={() => setShowModal(true)}
      >
        <Config />
      </Wrapper>
      <Modal
        size="lg"
        centered
        show={showModal}
      >
        <Modal.Body>
          <h4>{title}</h4>
          <h6>CONFIG</h6>
          <pre>
            {JSON.stringify(data, null, 2)}
          </pre>
          <h6>i18n</h6>
          <pre>
            {JSON.stringify(i18n, null, 2)}
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Copy onClick={() => navigator.clipboard.writeText(JSON.stringify(data, null, 2))}>
            Copiar Config
          </Copy>
          <Copy onClick={() => navigator.clipboard.writeText(JSON.stringify(data, null, 2))}>
            Copiar i18n
          </Copy>
          <Back onClick={()=> setShowModal(false)}>
            Voltar
          </Back>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Button